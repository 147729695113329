// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("styles/application.css");

global.toastr = require("toastr")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

function generateExcel(url){
    let tenant = document.getElementById('tenant').innerText;
    fetch(url)
    .then(response => response.blob())
    .then(blob => URL.createObjectURL(blob))
    .then(uril => {
        var link = document.createElement('a');
        link.href = uril;
        if(tenant === 'Maquina'){
            link.download = 'autopagos';
        }else{
            link.download ='copagos';
        }
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }); 
}

function handler(){
    const tenant = document.getElementById('tenant').innerText;

    if(tenant === 'Maquina'){

        const maquina = document.getElementById('maquina').value;
        const cierre = document.getElementById('cierre').value;
        const fechaInicial = document.getElementById('inicial').value.split('-').reverse().join().replaceAll(',','/');
        const fechaFinal = document.getElementById('final').value.split('-').reverse().join().replaceAll(',','/');

        if (fechaInicial && fechaFinal){
            url = `https://reportesemi.armandozaratem.com/api/v1/movimientos.xlsx?id_maquina=${maquina}&cierre=${cierre}&desde=${fechaInicial}&hasta=${fechaFinal}&tenatid=parking`
            generateExcel(url)
        }else {
            alert('Por favor ingrese fecha inicial y fecha final.')
        }

    }else {

        const sede = document.getElementById('sede').value;
        const cierre = document.getElementById('cierre').value;
        const fechaInicial = document.getElementById('inicial').value.split('-').reverse().join().replaceAll(',','/');
        const fechaFinal = document.getElementById('final').value.split('-').reverse().join().replaceAll(',','/');

        if (fechaInicial && fechaFinal){
            url = `https://reportesemi.armandozaratem.com/api/v1/movimientos.xlsx?sede=${sede}&cierre=${cierre}&desde=${fechaInicial}&hasta=${fechaFinal}`
            generateExcel(url)
        }else {
            alert('Por favor ingrese fecha inicial y fecha final.')
        }
    }
}



document.addEventListener('turbolinks:load', () => {  
    const clickButton = document.getElementById("app");

    if(clickButton){
        clickButton.addEventListener("click", handler);  
    }     
  });